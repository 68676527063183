<template>
  <div>
    <div class="sm:hidden">
      <select
        class="form-select block w-full"
        @change="$router.push($event.target.value)"
      >
        <option v-for="tab in tabs" :key="tab.id">{{ tab.label }}</option>
      </select>
    </div>

    <div class="hidden sm:block">
      <nav class="flex">
        <router-link
          v-for="tab in tabs"
          :key="tab.id"
          :to="tab.to"
          class="px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800 focus:bg-gray-200"
          active-class="text-gray-800 bg-gray-200 focus:bg-gray-300"
        >
          {{ tab.label }}
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { range, reverse } from 'ramda';
export default {
  name: 'YearTabs',
  props: {
    from: { type: Number },
    until: { type: Number },
    value: { type: Number },
    params: { type: Object, default: () => ({}) },
  },
  computed: {
    years() {
      const from = this.from ?? new Date().getFullYear() - 3;
      const until = this.until ?? new Date().getFullYear();
      return range(from, until + 1);
    },
    tabs() {
      return reverse(this.years).map(year => ({
        id: year,
        label: year,
        to: { name: 'sheets-year', params: { ...this.params, year } },
      }));
    },
  },
};
</script>
