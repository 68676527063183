<template>
  <article
    class="bg-white mt-8 first:mt-0 border border-gray-200 rounded p-4 shadow hover:shadow-2xl"
  >
    <div class="flex flex-col md:flex-row justify-between">
      <div>
        <div
          class="-mt-4 -ml-4 pl-4 w-48 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"
        >
          {{ $t('sheet_card_work_week') }}
        </div>
        <div class="text-2xl lg:text-4xl font-medium pt-2">
          <router-link
            class="blue-500"
            :to="{ name: 'sheet', params: { cid, aid, sid: sheet.id } }"
          >
            {{ sheet.startDate | week }}
          </router-link>
        </div>
        <div class="pb-2 whitespace-no-wrap">
          <interval-name :interval="sheet" />
        </div>
      </div>
      <summaries :summaries="summaries" />
    </div>
    <week-notices :notices="sheet.notices"/>
    <div class="mt-2">
      <div v-if="isManagerRW">
        <div class="flex justify-end">
          <sqr-button
            class="mr-2"
            icon="trash"
            tooltip="buttons_remove"
            @click="removeConfirm(sheet)"
            :is-loading="removing[path]"
          />
          <status-buttons
            :status="sheet.status"
            :is-loading="moderating[sheet.id]"
            @change="moderate(sheet, $event)"
            :options="['pending', 'accepted']"
          />
        </div>
        <sqr-error-note class="mt-2" :error="moderateError[sheet.id]" />
        <sqr-error-note class="mt-2" :error="removeError[sheet.id]" />
      </div>
      <div v-else></div>
    </div>
  </article>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import week from '@/filters/week';
import name from '@/filters/name';

import SqrButton from '@/sqrd/SqrButton';
import SqrErrorNote from '@/sqrd/SqrErrorNote';

import IntervalName from '@/components/IntervalName';
import Summaries from '@/components/Summaries';
import WeekNotices from '@/components/WeekNotices';
import StatusButtons from '@/components/StatusButtons';

export default {
  name: 'SheetCard',
  components: {
    SqrButton,
    IntervalName,
    Summaries,
    WeekNotices,
    StatusButtons,
    SqrErrorNote,
  },
  filters: { week },
  props: {
    cid: String,
    aid: String,
    sheet: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('perms', ['isManagerRW']),
    ...mapState('moderate', ['moderating', 'moderateError']),
    ...mapState('remove', ['removing', 'removeError']),
    summaries() {
      return this.sheet?.summaries?.filter(sum => !sum.hide) ?? [];
    },
    path() {
      const sid = this.sheet?.id;
      return `companies/${this.cid}/accounts/${this.aid}/sheets/${sid}`;
    },
  },
  methods: {
    ...mapActions('moderate', ['mod']),
    ...mapActions('remover', ['remove']),
    moderate(sheet, status) {
      this.mod({ type: 'sheet', doc: sheet, status });
    },
    removeConfirm() {
      const sheet = this.sheet;
      if (
        confirm(
          this.$t('sheet_remove_confirm', { name: name(sheet) ?? sheet.id })
        )
      ) {
        this.remove({ path: this.path });
      }
    },
  },
};
</script>
