<template>
  <main class="bg-gray-100 min-h-screen">
    <section class="px-2 md:px-0 py-2 md:py-4" v-if="isManager">
      <div class="mx-auto max-w-5xl">
        <sqr-progress v-if="cLoading || aLoading" />
        <nav
          class="breadcrumb"
          aria-label="breadcrumbs"
          v-if="company && account"
        >
          <!-- prettier-ignore -->
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'companies' }"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }"><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{ name: 'sheets', params: { cid, aid } }"><a>{{ $t('breadcrumb_account_sheets') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-2 md:py-4">
      <div class="mx-auto max-w-5xl">
        <div class="flex space-x-6 items-center" v-if="account">
          <year-tabs class="flex-1 sm:flex-none" :from="account.yearFrom" />
          <sqr-button
            :label="$t('sheets_index_refresh')"
            @click="index()"
            :loading="indexing"
            color="transparent"
            v-if="isAdmin"
          />
        </div>

        <sqr-progress v-show="loading" class="my-2" />
        <sqr-error-banner :error="loadError" />
        <sqr-error-banner :error="indexError" />

        <router-link
          v-if="nextSid"
          :to="{ name: 'sheet', params: { cid, aid, sid: nextSid } }"
          class="block hover:text-blue-100 bg-blue-50 hover:bg-blue-600 mt-8 first:mt-0 border-dashed hover:border-blue-600 border-blue-400 rounded p-6 border-2 text-2xl font-medium"
        >
          <fa class="mr-1" :icon="['fal', 'plus']" />
          {{ $t('sheets_create_next', { week: nextSid }) }}
        </router-link>

        <sheet-card
          v-for="sheet in sheets"
          :key="sheet.id"
          :sheet="sheet"
          :cid="cid"
          :aid="aid"
        />

        <router-link
          v-if="previousSid"
          :to="{ name: 'sheet', params: { cid, aid, sid: previousSid } }"
          class="block hover:text-blue-100 bg-blue-50 hover:bg-blue-600 mt-8 first:mt-0 border-dashed hover:border-blue-600 border-blue-400 rounded p-6 border-2 text-2xl font-medium"
        >
          <fa class="mr-1" :icon="['fal', 'plus']" />
          {{ $t('sheets_create_next', { week: previousSid }) }}
        </router-link>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { sortWith, descend, prop, groupBy, pick, fromPairs } from 'ramda';
import { DateTime } from 'luxon';

import account from './account';

import SqrProgress from '@/sqrd-ui/SqrProgress';

import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import YearTabs from '@/components/YearTabs';
import SheetCard from '@/components/SheetCard';

export default {
  name: 'Sheets',
  mixins: [account],
  props: {
    year: { type: String, default: () => String(new Date().getFullYear()) }
  },
  components: {
    SqrProgress,
    SqrButton,
    SqrErrorBanner,
    CompanyName,
    AccountName,
    YearTabs,
    SheetCard
  },
  computed: {
    ...mapState('sheetsIndex', ['doc', 'loading', 'loadError']),
    sheets() {
      const sheets = Object.entries(this.doc?.sheetsById ?? {}).map(
        ([id, sheet]) => ({
          ...sheet,
          id
        })
      );
      return sortWith([descend(prop('startDate'))])(sheets);
    },
    ...mapGetters('perms', ['isManager', 'isAdmin']),
    nextSid() {
      if (this.loading) return null;
      if (this.sheets?.length === 0) return null;
      const today = DateTime.local().startOf('day');
      const previous = DateTime.fromISO(this.sheets[0].startDate);
      const startDate = previous.plus({ weeks: 1 });
      if (startDate > today) return null;
      if (startDate.weekYear !== parseInt(this.year)) return;
      return startDate.toISOWeekDate().replace(/-1$/, '');
    },
    previousSid() {
      const length = this.sheets?.length;
      const yearCurrent = new Date().getFullYear();
      const yearParam = this.year && parseInt(this.year);
      if (length > 0) {
        const last = DateTime.fromISO(this.sheets[length - 1].startDate);
        const startDate = last.minus({ weeks: 1 });
        if (startDate.weekYear !== parseInt(this.year)) return null;
        return startDate.toISOWeekDate().replace(/-1$/, '');
      } else if (!yearParam || yearParam === yearCurrent) {
        // current week of year
        const startDate = DateTime.local().startOf('week');
        return startDate.toISOWeekDate().replace(/-1$/, '');
      } else {
        // last week of year
        if (!yearParam) return null;
        const weeksInWeekYear = DateTime.local(yearParam).weeksInWeekYear;
        const startDate = DateTime.fromObject({
          weekYear: yearParam,
          weekNumber: weeksInWeekYear,
          weekDay: 1
        });
        return startDate.toISOWeekDate()?.replace(/-1$/, '');
      }
    },
    sid() {
      return DateTime.local()
        .startOf('week')
        .toISOWeekDate()
        .replace(/-1$/, '');
    }
  },
  data() {
    return {
      indexing: false,
      indexError: null
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    '$route.params.year'() {
      this.load();
    }
  },
  methods: {
    ...mapActions('sheetsIndex', ['sub']),
    async load() {
      const cid = this.cid;
      const aid = this.aid;
      const year = this.year;
      const path = `companies/${cid}/accounts/${aid}/sheets-indexes/${year}`;
      this.sub({ path });
    },
    async index() {
      try {
        this.indexing = true;
        this.indexError = null;
        const cid = this.cid;
        const aid = this.aid;
        const path = `companies/${cid}/accounts/${aid}/sheets`;
        const snaps = await this.$db()
          .collection(path)
          .get();

        const sheetsWithId = snaps.docs.map(snap => ({
          ...snap.data(),
          path: snap.ref.path,
          id: snap.id
        }));
        const sheetsByIsoYear = groupBy(
          ({ id }) => id?.substring(0, 4),
          sheetsWithId
        );
        const field = [
          'path',
          'startDate',
          'endDate',
          'summaries',
          'status',
          'updated',
          'accepted',
          'denied',
          'notices',
          'noticesCount'
        ];
        const batch = this.$db().batch();
        Object.entries(sheetsByIsoYear).forEach(([year, sheets]) => {
          const pairs = sheets.map(sheet => [sheet.id, pick(field, sheet)]);
          const sheetsById = fromPairs(pairs);
          const idx = { year, sheetsById };
          const path = `companies/${cid}/accounts/${aid}/sheets-indexes/${year}`;
          const ref = this.$db().doc(path);
          batch.set(ref, idx);
        });
        await batch.commit();

        const yearFrom = Math.min(
          ...Object.keys(sheetsByIsoYear)
            .map(parseInt)
            .filter(v => v > 2016)
        );
        await this.$db()
          .doc(`companies/${cid}/accounts/${aid}`)
          .update({ yearFrom });
      } catch (error) {
        this.indexError = error;
      } finally {
        this.indexing = false;
      }
    }
  }
};
</script>
